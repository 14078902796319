import React, { useState, useEffect } from 'react';
import {Form, Button, Row, Col, InputGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SubcategoryService from '../../service/SubcategoryService';
import LocationService from '../../service/LocationService';

const AdListingHeader = ({ initialLocation, initialSubcategoryId }) => { 
  const [location, setLocation] = useState('');
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedShopType, setSelectedShopType] = useState('');  
  const [showSubcategory, setShowSubcategory] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLocation(initialLocation?.location);
  }, [initialLocation]);

  useEffect(() => {
    fetchSubcategories();
  }, []);

  useEffect(() => {
    if (subcategories.length > 0 && initialSubcategoryId) {
      const matchedSubcategory = subcategories.find(sub => sub.id == initialSubcategoryId);
      if (matchedSubcategory) {
        setSelectedSubcategory(matchedSubcategory.name);
      }
    }
  }, [subcategories, initialSubcategoryId]);

  const fetchSubcategories = async () => {
    try {
      const subcategoriesResponse = await SubcategoryService.getSubcategoriesByCategory();
      setSubcategories(subcategoriesResponse);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleLocationChange = async (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);

    if (newLocation.length >= 3) {
      try {
        const response = await LocationService.getLocationByName(newLocation);
        setLocationSuggestions(response);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Error fetching location suggestions:', error);
      }
    } else {
      setShowSuggestions(false);
    }
  };

  const handleLocationSelect = (selectedLocation) => {
    setLocation(selectedLocation.location);
    setShowSuggestions(false);
  };

  const handleSearch = () => {
    const selectedSubcategoryData = subcategories.find(sub => sub.name === selectedSubcategory);
    if (selectedSubcategoryData) {
      sessionStorage.setItem('subcategoryId', selectedSubcategoryData.id);
    }

    sessionStorage.setItem('shopType', selectedShopType);
    const formattedSubcategory = selectedSubcategory.toString().trim().replace(/\s+/g, '-');
    const searchlocation = location.trim().replace(/,\s*/g, '-');

    const url = `/service-near-me/${formattedSubcategory}/${searchlocation}`;
    navigate(url);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setShowSubcategory(false);
  };

  return (
    <div className="ad-listing-header"> 
       <Form onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
        <Row className="align-items-center justify-content-center internal_search-style">
          <Col md={3} sm={12} className='internal_category_search'> 
            <InputGroup>
              <Form.Control             
                type="text"
                placeholder="Choose Service"
                value={selectedSubcategory}
                onClick={() => setShowSubcategory(!showSubcategory)}
                readOnly
              />
            </InputGroup>
            {showSubcategory && (
              <Dropdown.Menu show className="c-width"> 
                {subcategories.map((subcategory) => (
                  <Dropdown.Item
                    key={subcategory.id}
                    onClick={() => handleSubcategorySelect(subcategory.name)}
                  >
                {subcategory.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Col>
          <Col md={3} sm={12} className='internal_location px-0'> 
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Enter your city, state"
                value={location}
                onChange={handleLocationChange}
              />
              <Button variant="outline-secondary">
                <i className="fas fa-location-dot"></i>
              </Button>
            </InputGroup>
            {showSuggestions && (
              <Dropdown.Menu show className="c-width">
                {locationSuggestions.map((suggestion, index) => (
                  <Dropdown.Item key={index} onClick={() => handleLocationSelect(suggestion)}>
                    {suggestion.location}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Col>
          <Col md={3} sm={12} className='internal_workType'> 
            <Form.Control
              as="select"
              value={selectedShopType}
              onChange={(e) => setSelectedShopType(e.target.value)}
            >
              <option value="">Shop/Mobile</option>
              <option value="Shop">Shop</option>
              <option value="Mobile">Mobile</option>
            </Form.Control>
          </Col>
          <Col md={3} sm={12} className='search_btn'>
            <Button variant="primary" className="btn-block" type="submit">
              <i className="fa fa-search"></i> Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdListingHeader;
