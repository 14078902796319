import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseurl+"api/subcategories";

const getSubcategoriesByCategory = async () => {
    try {
        const response = await axios.get(`${API_URL}/byCategoryAll/1`);
        return response.data;
    } catch (error) {
        throw new Error(error.response ? error.response.data : 'Failed to get Subcategory');       
    }
};

const SubcategoryService = {
    getSubcategoriesByCategory
};

export default SubcategoryService;