import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseurl+"api/advertisements";

const searchAdvertisements = async (params) => {
    try {
        const response = await axios.get(API_URL+'/search', { params });
        return response.data;
    } catch (error) {
        console.error('Error searching advertisements:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to search advertisements');
    }
};

const getAdvertisementById = async (adId) => {
    try {
        const response = await axios.get(`${API_URL}/${adId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching advertisements:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to fetch advertisements');
    }
};

const AdvertisementService = {
    searchAdvertisements,
    getAdvertisementById
};

export default AdvertisementService;
