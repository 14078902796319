import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseurl+"api/services";

const getServiceById = async (id, token) => {
    try {
        const response = await axios.get(`${API_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting Service:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to get Service');       
    }
};

const getServiceByIds = async (ids) => {
    try {
        const response = await axios.post(`${API_URL}/bylist`, ids);
        return response.data;
    } catch (error) {
        console.error('Error getting Service:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Failed to get Service');       
    }
};

const ServicesService = {
    getServiceById,
    getServiceByIds
};

export default ServicesService;