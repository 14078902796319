import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import ServicesService from '../../service/ServicesService';
import imageService from '../../service/ImageServie';

function AdCard({ advertisement, onViewDetails }) {
  const [showNumber, setShowNumber] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    // Format phone number as (XXX) XXX-XXXX
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handlePhoneClick = (phone) => {
    if (showNumber) {
       // Initiate a call (this will only work if your environment supports such functionality)
      window.location.href = `tel:${phone}`; }
    else {
      // Make the phone number visible
      setShowNumber(true);
    }
  };

  return (<>
    <Card className="mb-3 internal_ads_row col-md-12 p-md-2 p-0 border-0">
      <Row className='mx-0 justify-content-center'>
        {advertisement.imageName && (
          <Col md={3} className='p-0 align-content-around ad_banner rounded'>
            <Card.Img className='rounded' variant="top"
              src={`${imageService.getAdsImage}${advertisement.imageName}`}
              alt={advertisement.companyName}
            />
          </Col>
        )}
        <Col md={advertisement.imageName ? 9 : 12}>

          <Card.Body className='p-0 planCard-style text-left h-100 position-relative py-1'>
            <Row>
              <Col md={12} className='planInfo-style'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='cardText'>
                      <span className='ad_miles mb-0'>{advertisement.distance.toFixed(1)} miles</span>
                      <Card.Title>{advertisement.companyName}</Card.Title>
                      <p className='ad_address mb-0'><i className="fas fa-location-dot"></i> {advertisement.city}, {advertisement.state}</p>
                      <p className='ad_email mb-0'><i className="fa fa-envelope"></i> {advertisement.email || 'Not Available'}</p>
                    </div>

                    <div className="ad_description">
                      <p className='mb-0'>{advertisement.description}</p>
                    </div>

                  </div>

                  <div className='col-md-7 px-md-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='bd_plan_contact'>
                        <button className='btn mb-2' type="button" onClick={()=>handlePhoneClick(advertisement.phone)}><i className="fa fa-phone"></i> {showNumber ? formatPhoneNumber(advertisement.phone) : "Phone No."}</button>
                      </div>
                      <div className='ad_services'>
                        <h6 className='ad_hours'><i className="far fa-clock"></i> {advertisement.workingHours}</h6>
                      </div>

                    </div>

                    <div className='ad_services mt-md-0 mt-2'>
                      <span className='d-inline-block work_span mr-md-4 mr-1'><h6 className='ad_worktype'>Work Type : <b>{advertisement.shopType}</b></h6></span>
                      <ul className='ad_serviceList'>
                        {advertisement.services.slice(0, 4).map((service, index) => (
                          <li key={index}>{service}</li>
                        ))}
                      </ul>
                    </div>

                  </div>
                </div>

                {advertisement.planId === 3 && (
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='ad_special_offer px-md-2 inter_ad_special'>
                      <div className='highlight_text'>
                        <h3>Highlights</h3>
                      </div>
                      <div className='special_offer_content'>
                        <ul>
                          {advertisement.highlight.split(',').map((highlight, index)=>(
                            <li key={index}>{highlight.trim() || 'Contact Us For Special Deals & Discounts....'}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                )}

              </Col>
            </Row>

            <div className='row'>
              <div className='absolute_priceDiv d-flex justify-content-end align-items-center px-0 w-100'>
                <div className="planPrice-style text-center d-flex justify-content-end">
                  <Button className='planBtn' variant="primary" onClick={() => onViewDetails(advertisement.id, advertisement.companyName)}> <i className="far fa-eye"></i> View Info</Button>
                </div>
              </div>
            </div>

          </Card.Body>
        </Col>
      </Row>
    </Card>

  </>

  );
}

export default AdCard;