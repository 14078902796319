import BaseUrl from '../util/BaseUrl';

const getPlanImage = BaseUrl.baseurl+'image/plansimage/';

const getAdsImage = BaseUrl.baseurl+'image/adsimage/';

const getSaleBanner = BaseUrl.baseurl+'image/salebanner/';

const getBlogImage = BaseUrl.baseurl+'image/blogsimage/'

const imageService ={
 getPlanImage,
 getAdsImage,
 getSaleBanner,
 getBlogImage

};

export default imageService