import React from 'react';
import logo from '../../assets/logo.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import appStore from '../../assets/appstore.png';
import googlePlay from '../../assets/googleplay.png';

const Header = () => {
  return (
    <Navbar bg="white" variant="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/home">
          <img className='logo'
            src={logo}
            alt="Logo"
          // Adjust size as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto cust-nav align-items-center">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/plans">plans</Nav.Link>
            {/* <Nav.Link href="/about">About</Nav.Link> */}
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/blogs">Blog</Nav.Link>
            <Nav.Link className='img_btn' href="https://itunes.apple.com/us/app/breakdown-inc/id1459289134"><img src={appStore} className='img-fluid' alt={appStore} /></Nav.Link>
            <Nav.Link className='img_btn' href="https://play.google.com/store/apps/details?id=com.crinoid.breakdown&amp;hl=en"><img src={googlePlay} className='img-fluid' alt={googlePlay} /></Nav.Link>
            {/* <Nav.Link className='app_btn' href="/app"><i className="fa fa-mobile-alt"></i> &nbsp;Download App</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
