import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="text-white pt-5 pb-2">
      <Container>
        <Row>
          <Col className='foot_text my-3' md={12}>
            <ul>
              <li><a href="/home" className="text-white">Home</a></li>
              <li><a href="/plans" className="text-white">Plans</a></li>
              <li><a href="/about" className="text-white">About Us</a></li>
              <li><a href="/contact" className="text-white">Contact Us</a></li>
              <li><a href="/blogs" className="text-white">Blogs</a></li>
              <li><a href="/privacypolicy" className="text-white">Privacy Policy</a></li>
              <li><a href="/termsandcondition" className="text-white">Terms & Conditions</a></li>
              <li><a href="/refundandcancellation" className="text-white">Refund & Cancellation</a></li>
            </ul>
          </Col>

          <Col className='contact-detail my-md-3' md={12}>
          <ul className="contact-list">
            <li><a href="tel:+18008057820"><i className="fa fa-phone"></i>  800-805-7820</a></li>
            <li><a href="tel:+17027800780"><i className="fa fa-phone"></i> 702-780-0780</a></li>
            <li><a href="mailto:info@breakdowninc.com"><i className="fa fa-envelope"></i> info@breakdowninc.com</a></li>
          </ul>

          </Col>

          <Col className='social-icon mt-2' md={12}>
          <ul>
          <li><h6 className="text-white text-uppercase">Follow Us :</h6></li>
          <li><a href="https://www.facebook.com/breakdownincorp" target="_blank"><i className="fab fa-facebook-square"></i></a></li>
          <li><a href="https://www.instagram.com/breakdownincorp" target="_blank"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://twitter.com/Breakdown_inc" target="_blank"><i className="fab fa-x-twitter"></i></a></li>
          <li><a href="https://www.youtube.com/channel/UCQ-MhvO5t66wKUfvOJeyI7g?view_as=subscriber" target="_blank"><i className="fab fa-youtube"></i></a></li>
          <li><a href="https://www.linkedin.com/company/breakdown-incorp/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
          </ul>
          </Col>
          <Col md={12}>
          <hr/>
          </Col>

          <Col md={12} className="text-center mt-2 copy-right">
            <p>&copy; {new Date().getFullYear()} Breakdowninc.us. All rights reserved.</p>
          </Col>

        </Row>

      </Container>
    </footer>
  );
};

export default Footer;
