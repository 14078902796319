import axios from 'axios';
import BaseUrl from '../util/BaseUrl';

const API_URL = BaseUrl.baseurl+"api/email";

const sendEmail =async (formData) => {
    try {
        const response = await axios.post(API_URL+'/contact', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error Submit Details:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data : 'Sorry Failed to submit details, please try again.');
    }
}

const EmailService ={
    sendEmail
}

export default EmailService;