import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (<>
    <Helmet>
       <title>404: This page could not be found</title>
   </Helmet>

    <Container fluid className="d-flex justify-content-center align-items-center" style={{backgroundColor:"#ededed"}}>
      <Row className="text-center">
        <Col>
          <div className="my-md-5 my-3 py-3 py-md-5">
            <h1 className="display-1 fw-bold">404</h1>
            <h2 className="mb-md-4 mb-2">Oops! Page Not Found</h2>
            <p className="lead mb-3 mb-md-4">
              The page you are looking for does not exist.
            </p>
            <Link to="/">
              <Button size="lg" className='px-5' style={{backgroundColor:"#003e87", borderColor:"#003e87", color:"#fff"}}>Go to Home</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
    </>);
};

export default NotFound;
